import React from 'react';
// import DeveloperTechnologyMatcher from './components/DeveloperTechnologyMatcher';
// import SinastryForm from './components/SinastryForm';
// import ButtonSet from './components/buttons';
import DemoCard from './components/DemoCard';
function App() {
    return (
        <div className="p-8 bg-stone-50 min-h-screen">
            <DemoCard />
        </div>
    );
}

export default App;