import React, { useState, useRef, useEffect } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import ApexCharts from 'apexcharts';
const Card = ({ color, children }) => (
    <div className={`bg-white border border-${color}-200 rounded-lg shadow-md p-6`}>
        {children}
    </div>
);

const Title = ({ children }) => (
    <h2 className="text-2xl font-bold mb-4 text-gray-900 flex items-center">
        {children}
    </h2>
);

const Tooltip = ({ color, content }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className="relative ml-2">
            <InformationCircleIcon
                className={`h-5 w-5 text-${color}-500 hover:text-${color}-700 cursor-pointer`}
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            />
            {isVisible && (
                <div className={`absolute z-10 w-48 px-2 py-1 mt-2 text-sm font-medium text-white bg-${color}-700 rounded-md shadow-lg`}>
                    {content}
                </div>
            )}
        </div>
    );
};

const Button = ({ color, onClick, children, variant = 'primary', isLoading = false, isActive = false, isDisabled = false }) => {
    const getButtonClasses = () => {
        const baseClasses = `w-full md:w-auto px-4 py-2 rounded-md font-semibold transition-all duration-200 `;

        if (isDisabled) {
            if (variant === 'outline') {
                return `${baseClasses} border border-${color}-300 text-${color}-300 cursor-not-allowed opacity-50`;
            } else if (variant === 'text') {
                return `${baseClasses} text-${color}-300 cursor-not-allowed opacity-50`;
            } else {
                return `${baseClasses} bg-${color}-300 text-white cursor-not-allowed opacity-50`;
            }
        }

        if (isLoading) {
            if (variant === 'outline') {
                return `${baseClasses} border border-${color}-600 text-${color}-600 opacity-75 cursor-wait`;
            } else if (variant === 'text') {
                return `${baseClasses} text-${color}-600 opacity-75 cursor-wait`;
            } else {
                return `${baseClasses} bg-${color}-600 text-white opacity-75 cursor-wait`;
            }
        }

        let classes = baseClasses;

        switch (variant) {
            case 'outline':
                classes += isActive
                    ? `bg-${color}-100 border border-${color}-700 text-${color}-700`
                    : `border border-${color}-600 text-${color}-600 hover:bg-${color}-50 hover:border-${color}-700 hover:text-${color}-700 active:bg-${color}-100 active:border-${color}-800 active:text-${color}-800`;
                break;
            case 'text':
                classes += isActive
                    ? `text-${color}-800`
                    : `text-${color}-600 hover:text-${color}-700 active:text-${color}-800`;
                break;
            default: // primary
                classes += isActive
                    ? `bg-${color}-700 text-white`
                    : `bg-${color}-600 text-white hover:bg-${color}-700 active:bg-${color}-800`;
        }

        classes += ` focus:outline-none focus:ring-2 focus:ring-${color}-500 focus:ring-opacity-50`;

        return classes;
    };

    return (
        <button
            className={getButtonClasses()}
            onClick={onClick}
            disabled={isDisabled || isLoading}
        >
            {isLoading ? 'Loading...' : children}
        </button>
    );
};

const ColorOption = ({ color, onClick, isSelected }) => (
    <div
        className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 ${isSelected ? 'bg-gray-200' : ''}`}
        onClick={() => onClick(color)}
    >
        <div className={`w-4 h-4 rounded-full bg-${color}-600 mr-2`}></div>
        {color}
    </div>
);

const CustomDropdown = ({ colors, selectedColor, onColorChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                className="px-5 py-1 bg-white border border-gray-300 rounded-md flex items-center min-w-28 justify-between cursor-pointer"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className={`w-4 h-4 rounded-full bg-${selectedColor}-600 mr-2`}></div>
                {selectedColor}
            </button>
            {isOpen && (
                <div className="absolute mt-1 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10 max-h-60 overflow-y-auto">
                    {colors.map(color => (
                        <ColorOption
                            key={color}
                            color={color}
                            onClick={(c) => { onColorChange(c); setIsOpen(false); }}
                            isSelected={color === selectedColor}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

const colorMap = {
    slate: '#64748b',
    gray: '#6b7280',
    zinc: '#71717a',
    neutral: '#737373',
    stone: '#78716c',
    red: '#ef4444',
    orange: '#f97316',
    amber: '#f59e0b',
    yellow: '#eab308',
    lime: '#84cc16',
    green: '#22c55e',
    emerald: '#10b981',
    teal: '#14b8a6',
    cyan: '#06b6d4',
    sky: '#0ea5e9',
    blue: '#3b82f6',
    indigo: '#6366f1',
    violet: '#8b5cf6',
    purple: '#a855f7',
    fuchsia: '#d946ef',
    pink: '#ec4899',
    rose: '#f43f5e'
};

const ChartCard = ({ color }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartConfig = {
            series: [
                {
                    name: "Sales",
                    data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
                },
            ],
            chart: {
                type: "bar",
                height: 240,
                toolbar: {
                    show: false,
                },
            },
            title: {
                show: "",
            },
            dataLabels: {
                enabled: false,
            },
            colors: [colorMap[color] || colorMap.blue], // Χρησιμοποιούμε το colorMap
            plotOptions: {
                bar: {
                    columnWidth: "40%",
                    borderRadius: 2,
                },
            },
            xaxis: {
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
                labels: {
                    style: {
                        colors: "#616161",
                        fontSize: "12px",
                        fontFamily: "inherit",
                        fontWeight: 400,
                    },
                },
                categories: [
                    "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
                ],
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#616161",
                        fontSize: "12px",
                        fontFamily: "inherit",
                        fontWeight: 400,
                    },
                },
            },
            grid: {
                show: true,
                borderColor: "#dddddd",
                strokeDashArray: 5,
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
                padding: {
                    top: 5,
                    right: 20,
                },
            },
            fill: {
                opacity: 0.8,
            },
            tooltip: {
                theme: "dark",
            },
        };

        if (chartRef.current) {
            const chart = new ApexCharts(chartRef.current, chartConfig);
            chart.render();

            return () => {
                chart.destroy();
            };
        }
    }, [color]);

    return (
        <div className="relative flex flex-col rounded-xl bg-stone-100 bg-clip-border text-gray-700">
            <div className="relative mx-4 mt-4 flex flex-col gap-4 overflow-hidden rounded-none bg-transparent bg-clip-border text-gray-700 shadow-none md:flex-row md:items-center">
                <div className={`w-max rounded-lg bg-${color}-600 p-5 text-white`}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="h-6 w-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
                        ></path>
                    </svg>
                </div>
                <div>
                    <h6 className={`block font-sans text-base font-semibold leading-relaxed tracking-normal text-${color}-600 antialiased`}>
                        Bar Chart
                    </h6>
                    <p className="block max-w-sm font-sans text-sm font-normal leading-normal text-gray-700 antialiased">
                        Visualize your data in a simple way using the ApexCharts library.
                    </p>
                </div>
            </div>
            <div className="pt-6 px-2 pb-0">
                <div ref={chartRef}></div>
            </div>
        </div>
    );
};

const DemoCard = () => {
    const [color, setColor] = useState('blue');
    const [isLoading, setIsLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const colors = ['slate', 'stone', 'red', 'orange', 'amber', 'yellow',
        'lime', 'green', 'emerald', 'teal', 'cyan', 'sky', 'blue', 'indigo', 'violet',
        'purple', 'fuchsia', 'pink', 'rose'];

    const toggleState = (stateSetter, value) => {
        setIsLoading(false);
        setIsActive(false);
        setIsDisabled(false);
        stateSetter(value);
    };

    return (
        <div className="max-w-2xl mx-auto p-4">
            <div className="mb-4">
                <label className="block text-xl mb-2">Select Main Theme Color:</label>
                <CustomDropdown
                    colors={colors}
                    selectedColor={color}
                    onColorChange={setColor}
                />
            </div>
            <hr className="my-6" />
            <Card color={color}>
                <Title>
                    Card Title
                    <Tooltip color={color} content="This is a tooltip with additional information." />
                </Title>
                <p className="text-gray-700 mb-4">
                    This is a demo text displayed in the card. This text is in black color and provides information about the card's content.
                </p>

                <ChartCard color={color} />

                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 justify-between items-center mt-4">
                    <Button
                        color={color}
                        onClick={() => console.log('Primary button clicked')}
                        isLoading={isLoading}
                        isActive={isActive}
                        isDisabled={isDisabled}
                    >
                        Primary Button
                    </Button>
                    <Button
                        color={color}
                        variant="outline"
                        onClick={() => console.log('Secondary button clicked')}
                        isLoading={isLoading}
                        isActive={isActive}
                        isDisabled={isDisabled}
                    >
                        Secondary Button
                    </Button>
                    <Button
                        color={color}
                        variant="text"
                        onClick={() => console.log('Text button clicked')}
                        isLoading={isLoading}
                        isActive={isActive}
                        isDisabled={isDisabled}
                    >
                        Text Button
                    </Button>
                </div>
            </Card>

            <hr className="my-6" />
            <div className="mt-6 flex items-center jus space-x-4">
                <label className="block text-xl mb-2">Check Buttons State:</label>

                <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
                    <button
                        onClick={() => toggleState(setIsLoading, !isLoading)}
                        className={`px-2 py-1 rounded ${isLoading ? `bg-${color}-500 text-white` : 'bg-gray-200'}`}
                    >
                        Loading
                    </button>
                    <button
                        onClick={() => toggleState(setIsActive, !isActive)}
                        className={`px-2 py-1 rounded ${isActive ? `bg-${color}-500 text-white` : 'bg-gray-200'}`}
                    >
                        Active
                    </button>
                    <button
                        onClick={() => toggleState(setIsDisabled, !isDisabled)}
                        className={`px-2 py-1 rounded ${isDisabled ? `bg-${color}-500 text-white` : 'bg-gray-200'}`}
                    >
                        Disabled
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DemoCard;
